<template>
    <BModal
        id="addDataMapModal"
        ref="addDataMapModal"
        size="lg"
        modal-class="bar bar-primary"
        header-class="align-items-center"
        no-close-on-backdrop
        static
        visible
        @hide="hide"
        @shown="$refs.titleInput.focus()"
    >
        <template slot="modal-header">
            <h4 class="modal-title">
                Add a Data Map
            </h4>

            <FontAwesomeIcon
                icon="times"
                class="ml-auto modal-close"
                size="2x"
                @click="close"
            />
        </template>

        <div
            id="tooltipContainer"
            class="has-danger"
        />

        <ValidationObserver
            ref="validationObserver"
            tag="div"
            class="container-fluid"
        >
            <div class="row">
                <div class="col-12">
                    <ValidationProvider
                        v-slot="{errors}"
                        tag="div"
                        name="title"
                        rules="required"
                    >
                        <BFormGroup
                            :state="!errors.length"
                        >
                            <template slot="label">
                                Title
                                <div
                                    id="titlePopover"
                                    v-b-popover.hover="helpWordingHover('Title', 'title')"
                                    class="d-inline-block"
                                >
                                    <FontAwesomeIcon
                                        icon="info-circle"
                                        class="fa kc-help-wording"
                                        data-location="Data Map Add Modal"
                                    />
                                </div>
                            </template>

                            <BInput
                                ref="titleInput"
                                v-model="title"
                                type="text"
                                data-cy="title"
                                placeholder="Enter a title for the Data Map..."
                            />

                            <ValidationError
                                :errors="errors"
                                target="titlePopover"
                                tool-tip-container="tooltipContainer"
                                triggers=""
                            />
                        </BFormGroup>
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{errors}"
                        tag="div"
                        name="code"
                        rules="required"
                    >
                        <BFormGroup
                            :state="!errors.length"
                        >
                            <template slot="label">
                                Code
                                <div
                                    id="codePopover"
                                    v-b-popover.hover="helpWordingHover('Code', 'code')"
                                    class="d-inline-block"
                                >
                                    <FontAwesomeIcon
                                        icon="info-circle"
                                        class="fa kc-help-wording"
                                        data-location="Data Map Add Modal"
                                    />
                                </div>
                            </template>

                            <BInputGroup>
                                <BInput
                                    ref="codeInput"
                                    v-model="code"
                                    type="text"
                                    data-cy="code"
                                    placeholder="Enter a code for the Data Map..."
                                    @keypress="codeOverride = true"
                                />

                                <BInputGroupAppend>
                                    <BTooltip
                                        placement="top"
                                        target="resetButton"
                                        triggers="hover"
                                    >
                                        Resets code to be set from title
                                    </BTooltip>

                                    <BButton
                                        id="resetButton"
                                        variant="secondary"
                                        @click="resetCode"
                                    >
                                        Reset
                                    </BButton>
                                </BInputGroupAppend>
                            </BInputGroup>

                            <ValidationError
                                :errors="errors"
                                target="codePopover"
                                tool-tip-container="tooltipContainer"
                                triggers=""
                            />
                        </BFormGroup>
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{errors}"
                        tag="div"
                        name="policySystem"
                        rules="required"
                    >
                        <BFormGroup :state="!errors.length">
                            <template slot="label">
                                Policy System
                                <div
                                    id="policySystemPopover"
                                    v-b-popover.hover="helpWordingHover('Policy System', 'policy_system')"
                                    class="d-inline-block"
                                >
                                    <FontAwesomeIcon
                                        icon="info-circle"
                                        class="fa kc-help-wording"
                                        data-location="Data Map Add Modal"
                                    />
                                </div>
                            </template>

                            <Dropdown
                                id="policySystemDropdown"
                                v-model="policySystem"
                                :options="policySystemOptions"
                                label="name"
                                track-by="id"
                                data-cy="policySystemSelect"
                                placeholder="choose a policy system..."
                            >
                                <template
                                    slot="singleLabel"
                                    slot-scope="{ option }"
                                >
                                    {{ option.name }}
                                </template>
                            </Dropdown>

                            <ValidationError
                                :errors="errors"
                                target="policySystemPopover"
                                tool-tip-container="tooltipContainer"
                                triggers=""
                            />
                        </BFormGroup>
                    </ValidationProvider>

                    <RelatedItemSelect
                        @selection="taskId = $event.taskId"
                    />
                </div>
            </div>
        </ValidationObserver>

        <template slot="modal-footer">
            <button
                id="addDataMapButton"
                type="button"
                class="btn btn-primary"
                data-cy="addDataMap"
                :disabled="saving"
                @click="save"
            >
                <template
                    v-if="saving"
                >
                    <FontAwesomeIcon
                        class="mr-1"
                        icon="spinner"
                        pulse
                    />
                    Saving Data Map...
                </template>
                <template
                    v-else
                >
                    Begin Building Data Map
                    <FontAwesomeIcon
                        class="ml-1"
                        icon="angle-right"
                    />
                </template>
            </button>

            <button
                type="button"
                class="btn btn-secondary"
                data-cy="cancelDataMap"
                @click="close"
            >
                Cancel
            </button>
        </template>
    </BModal>
</template>

<script>
    import Dropdown from '@imt/vue-kit-car/src/components/Dropdown.vue';
    import ValidationError from '@imt/vue-toolbox/src/components/ValidationError.vue';
    import apiMixin from '@imt/vue-toolbox/src/mixins/api';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import RelatedItemSelect from '@imt/vue-versioning/src/components/RelatedItemSelect.vue';
    import snakeCase from 'lodash/snakeCase';
    import upperFirst from 'lodash/upperFirst';
    import {
        mapActions,
        mapGetters,
    } from 'vuex';

    export default {
        name: 'MDataMapAddModal',
        components: {
            Dropdown,
            ValidationError,
            RelatedItemSelect
        },
        mixins: [
            apiMixin,
            toastsMixin,
        ],
        metaInfo: {
            title: 'Add Data Map',
        },
        data() {
            return {
                booleanOptions: [
                    {
                        label: 'Yes',
                        value: true,
                    },
                    {
                        label: 'No',
                        value: false,
                    },
                ],
                title: '',
                code: '',
                setId: null,
                taskId: null,
                policySystem: [],
                codeOverride: false,
                saving: false,
                helpWording: {
                    title: 'Give this Data Map a title to be easily identified.',
                    code: 'Give this Data Map a code to be referenced by external applications.',
                    policy_system: 'Choose which Policy System this Data Map is for - or choose All to allow for running for any Policy System.',
                },
            };
        },
        computed: {
            policySystemOptions() {
                return [
                    {
                        value: '',
                        text: 'All',
                    },
                    ...this.formattedPolicySystems?.map((policySystem => {
                        return {
                            value: policySystem.id,
                            text: policySystem.title,
                        };
                    })),
                ];
            },
            ...mapGetters('toolbox', [
                'formattedPolicySystems',
            ]),
        },
        watch: {
            title(newValue) {
                if (!this.codeOverride) {
                    this.code = snakeCase(newValue);
                }
            },
        },
        methods: {
            helpWordingHover(title, content) {
                return {
                    title,
                    content: this.helpWording[content],
                    template: `<div class="popover kc-help-wording-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`,
                };
            },
            async save() {
                this.saving = true;
                let isValid = await this.$refs.validationObserver.validate();

                if (!isValid) {
                    this.saving = false;

                    return false;
                }

                try {
                    let response = await this.createDataMap({
                        title: this.title,
                        code: this.code,
                        setId: this.setId || null,
                        taskId: this.taskId || null,
                        policySystemId: this.policySystem[0].value || null,
                    });

                    this.success('Data Map successfully created!');
                    await this.$router.push({
                        name: 'admin.datamaps.builder',
                        params: {dataMapId: response.id, versionId: response.versions[0].id},
                    });

                    this.resetData();
                } catch (e) {
                    this.error(e.code === 'ERR_BAD_RESPONSE' ? e?.message : upperFirst(this.apiResponseErrors(e)), 'Failed to Create Data Map');
                    this.saving = false;
                }
            },
            async close() {
                await this.$router.push({name: 'admin.datamaps.list'});
            },
            async hide() {
                this.resetData();
                await this.$router.push({name: 'admin.datamaps.list'});
            },
            resetData() {
                Object.assign(this.$data, this.$options.data());
                this.$refs.validationObserver.reset();
            },
            resetCode() {
                this.codeOverride = false;
                this.code = snakeCase(this.title);
            },
            ...mapActions('mapping', [
                'createDataMap',
            ]),
        },
    };
</script>

<style
    lang="sass"
>
    body
        &,
        &.dark.mode
            .modal .form-group
                font-weight: normal
                margin-bottom: 1rem

                &,
                &.is-valid,
                &.is-invalid
                    label,
                    legend
                        width: 100%
                        font-weight: bold
</style>

<style
    lang="sass"
    scoped
>
    .modal-close
        opacity: 0.5
        transition: opacity 250ms

        &:hover
            opacity: 1.0
            cursor: pointer

    .modal
        .tooltip
            z-index: 1080

        .popover
            z-index: 1081
</style>
